import EventBus from '@/event-bus';
import { defineStore } from 'pinia';
import { RouteLocationNormalized } from 'vue-router';
import { nextTick } from 'vue';
import { GETTER_WRAPPER_EVENTS } from '@/const';

interface IPageHistory {
  id: string;
  path: string;
}

let observer = null as null | MutationObserver;
let intervalRef = 0 as number;
let lastMutationTimestamp = 0;

const onEvent = function() {
  lastMutationTimestamp = Date.now();
  nextTick(() => EventBus.$emit('page-content-updated'));
};

export const usePageStore = defineStore('page', {
  state: () => ({
    isLoading: false,
    history: [] as IPageHistory[],
    lastFocusElement: {
      router: null as null | HTMLElement,
      sidePanel: null as null | HTMLElement
    },
    currentRoute: null as null | RouteLocationNormalized
  }),
  actions: {
    watchLoadingState: function() {
      // Reset loading state to avoid duplicate listening
      this.unwatchLoadingState();

      // Reset timer timestamp
      lastMutationTimestamp = Date.now();

      // Listen every time the DOM childList change (new node meaning probably bigger page)
      observer ??= new MutationObserver(onEvent);
      observer.observe(document.querySelector('#root') as HTMLElement, { childList: true, subtree: true });
      // Listen every time Getter Wrapper emits something (possibly reloading content)
      EventBus.$on(GETTER_WRAPPER_EVENTS.UPDATE, onEvent);

      this.isLoading = true;
      // Every seconds, check if there has not been a change in the last second
      intervalRef = window.setInterval(() => {
        if (Date.now() - lastMutationTimestamp > 1000) {
          // Then we stop looking, and consider the page to be stable
          this.unwatchLoadingState();
        }
        // Otherwise, we keep on going
      }, 1000);
    },
    unwatchLoadingState: function() {
      this.isLoading = false;
      clearInterval(intervalRef);
      observer?.disconnect();
      EventBus.$off(GETTER_WRAPPER_EVENTS.UPDATE, onEvent);
    }
  }
});
