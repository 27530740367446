type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export function deepFreeze<T>(obj: T) {
  const propNames = Object.getOwnPropertyNames(obj) as (keyof T)[];
  for (const name of propNames) {
    const value = obj[name];
    if (typeof value === 'object' && !Object.isFrozen(value)) {
      deepFreeze(value);
    }
  }
  return Object.freeze(obj);
}

export function clone<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj)) as Writeable<T>;
}
